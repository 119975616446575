function tellUs() {
    $(".js-popup-tell-us, [href='#popup-tell-us']").click(function(e){
        e.preventDefault();
        $.fancybox.open( $( "#js-popup-tell-us" ), {
            hideScrollbar: true,
            buttons: [
                "close"
            ],
            baseClass: 'popup-tell-us-wrap',
        });
    })
}

export { tellUs };

